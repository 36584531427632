<template>
	<div style="width: 100%;height: 100%;">
		<div class="course-wrapper">
			<div class="course-wrapper-title">
				<span>周计划设置</span>
			</div>

			<div class="teaching_plan_setting">
				<el-form
					class="teaching_plan_setting_form"
					ref="teachingPlanSettingFormRules"
					:rules="teachingPlanSettingFormRules"
					:model="teachingPlanSettingForm"
					label-width="8.33333vw"
				>
					<el-form-item prop="process" label="一日流程：">
						<el-select
							v-model="teachingPlanSettingForm.process"
							@change="handleSelect"
							placeholder="请选择一日流程模版"
						>
							<el-option
								v-for="dayClass in dayClassOptinons"
								:key="dayClass.name"
								:label="dayClass.templateName"
								:value="dayClass.id"
							></el-option>
						</el-select>
					</el-form-item>

					<el-form-item prop="class" label="适用班级：">
						<checkboxAll
							:group="checkboxGroup"
							:checkedGroup="teachingPlanSettingForm.class"
							valueName="classLabel"
							labelName="className"
							@checked="classChceck"
						></checkboxAll>
					</el-form-item>

					<el-form-item prop="title" label="模版名称：">
						<el-input
							v-model="teachingPlanSettingForm.title"
							placeholder="请输入模版名称"
						></el-input>
					</el-form-item>
				</el-form>
			</div>
			<schedule
        v-if="plan"
				:scheduleData="plan"
				@scheduleDataEdit="scheduleDataEdit"
        @activityDelete="activityDelete"
			></schedule>

			<div class="teaching_plan_setting_footer">
				<div class="tpsf_button" align="right">
          <el-button @click="handleButtonCancel">取消</el-button>
					<el-button @click="handleButtonSava" type="primary">确定</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import schedule from '@/views/teachingProgram/components/schedule';
import checkboxAll from '@/views/teachingProgram/components/checkboxAll';
export default {
  name: 'TeachingPlanSetting',
  components: {
    schedule,
    checkboxAll,
  },
  inject: ['tagClose'],
  data() {
    let checkClassess = (rule, value, callback) => {
      console.log(this.checkboxGroup);
      if (this.classGroupData.length) {
        return callback();
      }
      callback('请选择适用班级');
    };
    return {
      param: '',
      teachingPlanSettingForm: {
        process: [],
        class: [],
        title: '',
      },
      classChecked: {},
      classGroupData: [],
      checkboxGroup: [],
      plan: null,
      teachingPlanSettingFormRules: {
        process: [
          { required: true, message: '请选择一日流程', trigger: 'blur' },
        ],
        class: [
          // { validator: checkClassess,  trigger: 'blur' },
          { required: true, validator: checkClassess, trigger: 'blur' },
        ],
        title: [{ required: true, message: '请输入模版名称', trigger: 'blur' }],
      },
      dayClassOptinons: [],
    };
  },
  async created() {
    // return;
    await this.$api
      .searchStudentsClass()
      .then(result => {
        console.log(result);
        let { code, data } = result.data;
        if (code) {
          return;
        }
        this.checkboxGroup = data;
        this.classGroupData = data;
      })
      .catch(err => {});

    await this.$api
      .searchLinkCheckList()
      .then(result => {
        let { code, data } = result.data;
        if (code) {
          return;
        }
        // console.log(data);
        let { records } = data;
        this.dayClassOptinons = records;

        data.map(item => {
          this.linkValue[item.value] = item.name;
          return item;
        });
      })
      .catch(error => {});
    // 读取班级列表
    this.searchTemplateData();
  },
  mounted() {

  },
  methods: {
    scheduleDataEdit(row) {
      let { data, link, dataType, _form, dataIndex } = row;

      // 添加活动确定
      switch (dataType) {
        case 'update': {
          let { processes }  = JSON.parse(JSON.stringify(this.plan));
          let arr = [];
          _form.week.forEach((item, index) => {
            let form = Object.assign({}, data);
            form.uniteCode = _form.uniteCode;
            form.planDay = item.toString();
            arr.push(form);
          });

          let item = processes[dataIndex[0]]['processes'][dataIndex[1]];
          item = item.map(_item => {
            console.log(_item);
            if (_item && _item.uniteCode === _form.uniteCode) {
              console.log(_item);
              _item = null;
              return _item;
            }
            return _item;
          });
          processes[dataIndex[0]].processes.splice(dataIndex[1], 1, item);
          processes[dataIndex[0]].processes.push(arr);
          let plan = JSON.parse(JSON.stringify(this.plan));
          plan.processes = processes;
          this.plan = null;
          this.$nextTick(() => {
            this.plan = plan;
          });
          break;
        }

        case 'insert': {
          let plan = this.plan;
          let linkActivity = [];
          let uniteCode = new Date().getTime();
          data.date.forEach(item => {
            let _data = Object.assign({}, data);
            _data.planDay = item;
            _data.uniteCode = uniteCode;
            linkActivity.push(_data);
          });
          if (!link.processes) {
            link.processes = [];
          }
          link.processes.push(linkActivity);
          this.plan = null;
          this.$nextTick(() => {
            this.plan = Object.assign({}, plan);
          });
        }
      }

    },
    // 选择一日流程
    handleSelect(value) {
      this.$api
        .searchTemplateDetails(value)
        .then(result => {
          let { code, data } = result.data;
          if (code) {
            return;
          }
          data.processes = data.list;
          this.plan = data;
          // console.log(result);
        })
        .catch(err => {});
      // console.log(value);
    },
    // 适用班级返回函数
    classChceck(data) {
      let { checkedData } = data;
      // let classes = [];
      this.classGroupData = checkedData;
      console.log(data);
      // checkedData.forEach(item => {
      //   classes.push(item.className);
      // });
    },
    searchTemplateData() {
      let { id } = this.$route.params;
      if (id !== 'insert') {
        console.log(id);
        this.$api
          .searchWeekTemplate(id)
          .then(result => {
            let { code, data } = result.data;
            if (code) {
              return;
            }
            let { templateName, dayConfigId } = data.d;
            let { classes } = data;
            let classValue = [];
            this.teachingPlanSettingForm.title = templateName;
            this.teachingPlanSettingForm.process = dayConfigId * 1;
            classes.forEach(item => {
              classValue.push(item.classesLabel);
            });
            this.checkboxGroup = [...this.checkboxGroup];
            this.teachingPlanSettingForm.class = classValue;
            console.log(classValue);
            // this.classChecked = {
            //   classValue,
            //   checnboxLength: this.checkboxGroup.length
            // }
            data.processes.map(item => {
              item.processes.map(item1 => {
                let date = [];
                item1.map((item2) => {
                  date.push(item2.planDay);
                });
                item1.map(item2 => {
                  item2.date = date;
                  return item2;
                });
                return item1;
              });
              return item;
            });
            this.plan = data;

          })
          .catch(error => {});
      }
    },
    // 模版数据转换保存数据
    templateDataToSaveData(data) {
      let dataArr = [];
      if (data) {
        let dataJson = {};
        data.forEach(item => {
          item.processes && item.processes.forEach(item1 => {
            console.log(item1);
            if (item1) {
              item1.forEach(item2 => {
                // 防止null
                if (item2 && item2.uniteCode) {
                  dataJson[item2.uniteCode] = Object.assign({}, item2);
                }
              });
            }
          });
        });
        for (let key in dataJson) {
          dataArr.push(dataJson[key]);
        }
      }
      return dataArr;
    },
    /**
		 * 确定-保存按钮
		 */
    handleButtonSava() {
      let flag = false;
      this.$refs['teachingPlanSettingFormRules'].validate(v => {
        flag = v;
      });
      console.log(flag);
      if (!flag) {
        return;
      }
      // return;
      let { id } = this.$route.params;
      let { title, process } = this.teachingPlanSettingForm;
      let _data = {
        dayConfigId: process,
        templateName: title,
        classes: this.classGroupData,
        datas: [
          ...this.templateDataToSaveData(this.plan.processes)
        ],
      };
      if (id !== 'insert') {
        _data.id = this.plan.d.id;
        this.$api
          .updateWeekTemplate(_data)
          .then(result => {
            let { code, msg } = result.data;
            if (code) {
              this.$message.error(msg);
              return;
            }
            this.$message.success('保存成功');
            this.handleButtonCancel();
          })
          .catch(err => {});
      } else {
        this.$api
          .insertWeekTemplate(_data)
          .then(result => {
            let { code, data, msg } = result.data;
            if (code) {
              this.$message.success(msg);
              return;
            }
            this.$route.params.id = data.id;
            this.$message.success('保存成功');
            this.handleButtonCancel();
          })
          .catch(err => {

          });
      }
      return;

    },
    /**
     * 按钮-取消
     */
    handleButtonCancel() {
      this.tagClose(this.$route.fullPath);
      this.$router.push('/weeklyPlanTemplate');
    },
    activityDelete(scope) {
      console.log(scope)
      let { processes }  = JSON.parse(JSON.stringify(this.plan));
      let { data } = JSON.parse(JSON.stringify(scope))
      let item = processes[scope.indexList[0]]['processes'][scope.indexList[1]];
      item = item.map(_item => {
        console.log(_item);
        if (_item && _item.uniteCode === scope.uniteCode) {
          console.log(_item);
          _item = null;
          return _item;
        }
        return _item;
      });
      processes[scope.indexList[0]].processes.splice(scope.indexList[1], 1, item);
      // processes[dataIndex[0]].processes.push(arr);
      let plan = JSON.parse(JSON.stringify(this.plan));
      plan.processes = processes;
      this.plan = null;
      this.$nextTick(() => {
        this.plan = plan;
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/common_c.scss";
.course-wrapper{
  width: 100%;
  .schedule_wrap{
    margin-top: 0;
    height: auto;
    .schedule{
      height: auto;
      max-height: unset;
    }
  }
}
.teaching_plan_setting {
	padding: 40px 0 0;
	.teaching_plan_setting_form {
		.el-select,
		.el-input {
			width: 350px;
		}
		.el-checkbox.checkbox_all {
			margin-right: 28px;
		}
		.el-checkbox-group {
			display: inline-block;
		}
	}
}
.teaching_plan_setting_footer {
	.tpsf_button {
    padding-right: 60px;
		padding-bottom: 40px;
	}
}
</style>
